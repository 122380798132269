import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import RouteNavigation from "./route";
import { ThemeProvider } from "./themes/ThemeContext";
import { AuthProvider } from "./context/AuthContext";
import { IntegrationProvider } from "./context/IntegrationContext";
import { BotProvider } from "./context/BotContext";
import { PricesProvider } from "./context/PricesContext";
import { PaymentsProvider } from "./context/PaymentsContext";
// import ThemeToggle from "./components/ThemeToggle";

const queryClient = new QueryClient();

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <PaymentsProvider>
            <PricesProvider>
              <BotProvider>
                <IntegrationProvider>
                  <AuthProvider>
                    <ToastContainer />
                    {/* <ThemeToggle /> */}
                    <RouteNavigation />
                  </AuthProvider>
                </IntegrationProvider>
              </BotProvider>
            </PricesProvider>
          </PaymentsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
