import * as yup from "yup";

// Schema de validação usando yup
export const priceSchema = yup.object().shape({
  price: yup
    .number()
    .required("Preço é obrigatório")
    .typeError("Preço deve ser um número")
    .min(1, "Preço deve ser no mínimo 1"),
  purchaseType: yup
    .string()
    .required("Tipo de compra é obrigatória")
    .oneOf(["avulso", "recorrente"], "Tipo de venda inválida"),
  frequency: yup
    .string()
    .required("Frequência é obrigatória")
    .oneOf(
      ["week", "month", "minute", "day", "fortnightly"],
      "Frequência inválida"
    ),
  typePayment: yup
    .string()
    .required("Forma de pagamento é obrigatória")
    .oneOf(["pix", "stripe"], "Forma de pagamento inválida"),
  currency: yup
    .string()
    .required("Moeda é obrigatória")
    .oneOf(["brl", "eur", "usd"], "Moeda inválida"),
});
