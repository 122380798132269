import { useLocation } from "react-router-dom";
import Button from "../../components/buttons";
import "./sucessPayment.css"; // Arquivo CSS para estilização
import { useEffect, useState } from "react";
import { getProduct } from "../../models/payments";
import { toast } from "react-toastify";

interface Product {
  botId: number;
  botUsername: string;
  description: string;
  groupId: number;
  id: string;
  nicknameAdmin: string;
  stripeProductId: string;
  title: string;
}
export const SucessPayment = () => {
  const [product, setProduct] = useState<Product | null>(null);
  const location = useLocation();
  // Extract the groupId from the query string
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");

  useEffect(() => {
    (async () => {
      try {
        const data = await getProduct({ productId: productId || "" });
        setProduct(data);
      } catch (error) {
        console.log(error);
        toast.error(`Grupo não encontrado, entre em contato com o suporte!`);
      }
    })();
  }, [productId]);

  return (
    <div className="success-container">
      <div className="success-content">
        <h1 style={{ margin: 0 }}>Você vai ver tudo! 🥵</h1>
        <p
          style={{
            fontWeight: "800",
            fontSize: 18,
            marginBottom: 48,
            marginTop: 24,
          }}
        >
          Para acessar o grupo VIP do Telegram, siga os passos abaixo:
        </p>
        <ol>
          <li style={{ fontWeight: "600", marginBottom: 24 }}>
            1 - Clique no botão abaixo para acessar o Bot de acesso.
            <br />
            <a
              href={`https://t.me/${product?.botUsername}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ marginTop: 24 }} title="Acessar BOT" />
            </a>
          </li>
          <li style={{ fontWeight: "600", marginBottom: 24 }}>
            2 - Escolha a opção e insira o e-mail cadastrado no pagamento.
          </li>
          <li style={{ fontSize: 18, fontWeight: "400" }}>
            Parabéns, você receberá o acesso ao link do grupo VIP.
          </li>
        </ol>
      </div>
    </div>
  );
};
