import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { pricesApi } from "../services/prices";
import { Price } from "../types/prices";
import { Values } from "../components/modal/price";

interface PricesContextType {
  prices: Price[];
  loadingSavePrice: boolean;
  loadingPrices: boolean;
  fetchPrices: ({ groupId }: { groupId: number }) => Promise<void>;
  addPriceItem: (item: Values, groupId: number) => void;
  updatePriceItem: (index: number, groupId: number, updatedItem: Price) => void;
}

// Crie o contexto com um valor padrão (vazio)
const PricesContext = createContext<PricesContextType>({
  prices: [],
  loadingSavePrice: false,
  loadingPrices: false,
  fetchPrices: async ({ groupId }: { groupId: number }) => {},
  addPriceItem: () => {},
  updatePriceItem: () => {},
});

// Crie um provedor de contexto
export const PricesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [loadingSavePrice, setLoading] = useState(false);
  const [loadingPrices, setLoadingPrices] = useState(false);

  const updatePricesWithGroupStatus = (
    prices: Price[],
    pricesForGroup: Price[]
  ) => {
    return prices.map((price) => {
      const isActiveForGroup = pricesForGroup.some(
        (groupPrice) => groupPrice.id === price.id
      );

      return {
        ...price,
        isActiveForGroup, // Adiciona a nova propriedade
      };
    });
  };

  // Função simulada para buscar preços
  const fetchPrices = async ({ groupId }: { groupId: number }) => {
    setLoadingPrices(true);
    try {
      const prices = await pricesApi.getPrices();
      const pricesForGroup = await pricesApi.getPricesForGroup({ groupId });
      let updatePrices = prices;
      if (pricesForGroup?.length) {
        updatePrices = await updatePricesWithGroupStatus(
          prices,
          pricesForGroup
        );
      }

      setPrices(updatePrices);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPrices(false);
    }
  };

  // Função para adicionar um novo item de preço
  const addPriceItem = async (item: Values, groupId: number) => {
    setLoading(true);
    try {
      const price: Price = {
        price: Number(item.price),
        currency: item.currency as "brl",
        purchaseDetails: {
          purchaseType: item.purchaseType,
          recurrencyPeriod: item.frequency,
        },
      };
      await pricesApi.savePrice(price);
      await fetchPrices({ groupId });
      //setPrices((prevPrices) => [...prevPrices, item]);
      toast.success("Item adicionado com sucesso!");
    } catch (error) {
      toast.error("Preço já existente!");
    } finally {
      setLoading(false);
    }
  };

  // Função para atualizar um item existente
  const updatePriceItem = async (
    index: number,
    groupId: number,
    updatedItem: Price
  ) => {
    const updatedPrices = [...prices];
    updatedPrices[index] = updatedItem;
    setPrices(updatedPrices);
    if (!updatedItem?.isActiveForGroup) {
      await pricesApi.removePriceFromGroup({
        groupId,
        priceId: updatedItem.id?.toString() as string,
      });
      toast.success("Preço desativado com sucesso!");
    } else {
      await pricesApi.savePriceToGroup({
        groupId,
        priceId: updatedItem.id?.toString() as string,
      });
      toast.success("Preço ativado com sucesso!");
    }
  };

  return (
    <PricesContext.Provider
      value={{
        prices,
        loadingSavePrice,
        loadingPrices,
        fetchPrices,
        addPriceItem,
        updatePriceItem,
      }}
    >
      {children}
    </PricesContext.Provider>
  );
};

// Hook personalizado para usar o contexto
export const usePricesContext = () => {
  const context = useContext(PricesContext);
  if (context === undefined) {
    throw new Error("usePricesContext must be used within a PricesProvider");
  }
  return context;
};
