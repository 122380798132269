import { useTheme } from "styled-components";
import Button from "../buttons";
import { SubContainer } from "../container";
import { TextMedium, TextMinimum } from "../texts/TextStyles";
import Toggle from "../toggle";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { groupApi } from "../../services/groups";
import { toast } from "react-toastify";
import { FileUploadContainer, HiddenInput, UploadButton } from "./styled";
import { InputFieldMultiLineIndependent } from "../Input/InputMultiline";
import { identificarTipoDeArquivo } from "../../utils/identifyLinkUrl";
import { FaUpload } from "react-icons/fa"; // Importe o ícone desejado
import Loader from "../lottie/loading";

export const CardGrupo = ({
  title,
  description,
  groupId,
  temp,
  isActive,
  loadingRemoveDescription,
  setIsModalPriceOpen,
  setTempGroup,
  setIsActiveGroup,
  getGroups,
  removeDescription,
}: {
  title: string;
  groupId: number;
  temp: boolean;
  isActive: boolean;
  description: { url?: string; text?: string };
  loadingRemoveDescription: boolean;
  getGroups: () => void;
  removeDescription: ({
    groupId,
    description,
  }: {
    groupId: number;
    description: { text?: string; url?: string };
  }) => void;
  setIsModalPriceOpen: ({
    isOpen,
    groupId,
  }: {
    isOpen: boolean;
    groupId: number;
  }) => void;
  setTempGroup: ({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }) => void;
  setIsActiveGroup: ({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }) => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | any>(null);
  const [descriptionTemp, setDescriptionTemp] = useState<{
    url?: string;
    text?: string;
  }>();
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    } else {
      setPreviewUrl(null);
    }
  };

  useEffect(() => {
    description && setDescriptionTemp(description);
  }, [description]);

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      selectedFile && formData.append("file", selectedFile);
      Boolean(descriptionTemp?.text) &&
        formData.append(
          "description",
          descriptionTemp?.text?.toString() as string
        );
      groupId?.toString() && formData.append("groupId", groupId?.toString());
      await groupApi.setDescription({ formData });
      getGroups && getGroups();
      toast.success("Descrição adicionada com sucesso!");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setPreviewUrl("");
      setDescriptionTemp({ url: "", text: "" });
    }
  };

  const getArq = (url: string) => {
    const response = identificarTipoDeArquivo(url);
    if (response === "imagem") {
      return (
        <img
          src={url}
          alt="Preview"
          style={{
            maxWidth: "100%",
            maxHeight: 200,
            alignSelf: "center",
            marginTop: 24,
          }}
        />
      );
    } else if (response === "vídeo") {
      return (
        <video
          src={url}
          controls
          style={{
            maxWidth: "100%",
            maxHeight: 200,
            alignSelf: "center",
            marginTop: 24,
          }}
        />
      );
    } else if (response === "audio") {
      return (
        <audio
          src={url}
          controls
          style={{ maxWidth: "100%", alignSelf: "center", marginTop: 24 }}
        />
      );
    }
  };

  return (
    <SubContainer style={{ gap: 12 }}>
      <div>
        <TextMedium
          style={{
            fontWeight: 800,
            color: theme.inputFocusBorder,
            marginBottom: 12,
          }}
        >
          {title}
        </TextMedium>
        <TextMinimum>{groupId}</TextMinimum>
      </div>
      <SubContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextMedium style={{ fontWeight: "bold" }}>
              Descreva seu Grupo
            </TextMedium>
            {description?.text && (
              <TextMinimum
                style={{ cursor: "pointer" }}
                onClick={() => {
                  removeDescription &&
                    removeDescription({
                      groupId,
                      description: { text: "", url: description.url },
                    });
                }}
              >
                {loadingRemoveDescription ? (
                  <div style={{ height: 50, width: 50 }}>
                    <Loader />
                  </div>
                ) : (
                  "Excluir Descrição"
                )}
              </TextMinimum>
            )}
          </div>
          <InputFieldMultiLineIndependent
            placeholder={""}
            name={""}
            value={descriptionTemp?.text}
            type={""}
            style={{ width: "100%", marginTop: 12 }}
            onChange={(event: any) =>
              setDescriptionTemp({
                ...descriptionTemp,
                text: event.target.value,
              })
            }
          />
          {description?.url && !previewUrl && getArq(description.url)}
          {description?.url && (
            <TextMinimum
              style={{ cursor: "pointer", marginTop: 14, alignSelf: "center" }}
              onClick={() =>
                removeDescription &&
                removeDescription({
                  groupId,
                  description: { text: "true", url: "" },
                })
              }
            >
              {loadingRemoveDescription ? (
                <div style={{ height: 50, width: 50 }}>
                  <Loader />
                </div>
              ) : (
                "Excluir arquivo da descrição"
              )}
            </TextMinimum>
          )}
          <FileUploadContainer>
            <HiddenInput
              type="file"
              accept="image/*,video/*,audio/*"
              onChange={handleFileChange}
              id="file-upload" // Adicione um ID para referência no label
            />
            <UploadButton htmlFor="file-upload">
              Adicionar arquivo(Áudio, Imagem ou Vídeo){"  "}
              <FaUpload />
            </UploadButton>
          </FileUploadContainer>

          {previewUrl && (
            <div style={{ marginTop: 12 }}>
              {selectedFile?.type.startsWith("image/") && (
                <img
                  src={previewUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: 200 }}
                />
              )}
              {selectedFile?.type.startsWith("video/") && (
                <video
                  src={previewUrl}
                  controls
                  style={{ maxWidth: "100%", maxHeight: 200 }}
                />
              )}
              {selectedFile?.type.startsWith("audio/") && (
                <audio src={previewUrl} controls style={{ maxWidth: "100%" }} />
              )}
            </div>
          )}
          {selectedFile ||
          (descriptionTemp?.text !== description?.text &&
            descriptionTemp?.text?.length) ? (
            <Button
              style={{ marginTop: 12 }}
              title="Salvar Descrição"
              onClick={handleUpload}
              loading={loading}
            />
          ) : null}
        </div>
      </SubContainer>
      <div>
        <Button
          style={{ width: "100%", marginBottom: 12 }}
          title={"Assinantes"}
          onClick={() => navigate(`/subscriptions/${groupId}`)}
        />
        <Button
          style={{ width: "100%" }}
          title={"Preços"}
          onClick={() =>
            setIsModalPriceOpen({
              isOpen: true,
              groupId: groupId,
            })
          }
        />
        <div style={{ display: "flex", gap: 16, marginTop: 24 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TextMinimum style={{ marginBottom: 14 }}>
              Acesso temporário?
            </TextMinimum>
            <Toggle
              initialState={temp}
              onToggle={() =>
                setTempGroup({
                  groupId: groupId,
                  tempValue: !temp,
                })
              }
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TextMinimum style={{ marginBottom: 14 }}>Habilitado</TextMinimum>
            <Toggle
              initialState={isActive}
              onToggle={() =>
                setIsActiveGroup({
                  groupId: groupId,
                  tempValue: !isActive,
                })
              }
            />
          </div>
        </div>
      </div>
    </SubContainer>
  );
};
