import { useTheme } from "styled-components";
import { SubContainer } from "../container";
import { TextMedium, TextMinimum } from "../texts/TextStyles";
import { DataSubscription } from "../../types/subscription";
import { Payment } from "../../types/payments";
import { formatFirebaseTimestamp } from "../../types/format";
import { isUserActive } from "../../utils/subscriptions";
import { FormatNamePrice } from "../../utils/formatNamePrice";

export function CardSubscription({
  subscription,
  payments,
}: {
  subscription: DataSubscription;
  payments?: Payment[];
}) {
  const theme = useTheme();
  const formattedEndDate = subscription.endDateSubscription
    ? formatFirebaseTimestamp(subscription.endDateSubscription)
    : null;
  console.log(formattedEndDate);
  // Data estática a ser comparada (14/10/2024 23:45)

  const isActive =
    formattedEndDate &&
    isUserActive({
      formattedEndDate: formattedEndDate as string,
    });
  return (
    <SubContainer
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: 18,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          gap: 6,
        }}
      >
        <TextMedium style={{ fontWeight: "800", color: theme.textGray }}>
          Termin{isActive ? "a" : "ou"} em
        </TextMedium>
        <TextMinimum style={{ fontWeight: "bold", color: theme.greenText }}>
          {formattedEndDate}
        </TextMinimum>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          gap: 6,
        }}
      >
        <TextMedium style={{ fontWeight: "800", color: theme.textGray }}>
          Período
        </TextMedium>
        <TextMinimum style={{ fontWeight: "bold", color: theme.greenText }}>
          {FormatNamePrice(subscription?.recurrencyPeriod)}
        </TextMinimum>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          gap: 6,
        }}
      ></div>
    </SubContainer>
  );
}
