import { PeriodOfAppointment } from "../types/prices";

export const FormatNamePrice = (name: PeriodOfAppointment) => {
  switch (name) {
    case PeriodOfAppointment.DAY:
      return "1 dia";
    case PeriodOfAppointment.FORTNIGHTLY:
      return "15 dias";
    case PeriodOfAppointment.MINUTE:
      return "2 minutos";
    case PeriodOfAppointment.MONTH:
      return "30 dias";
    case PeriodOfAppointment.WEEK:
      return "7 dias";
    default:
      return "";
  }
};
