// styledComponents.ts
import styled, { css } from "styled-components";
import { TextareaHTMLAttributes } from "react";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isFocused: boolean;
  hasValue: boolean;
  error?: boolean;
}

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledTextarea = styled.textarea<TextareaProps>`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px; /* Adiciona padding à direita, semelhante ao input */
  border-radius: 8px;
  border: ${(props) =>
    props.error
      ? "2px solid red"
      : "none"}; /* Adiciona uma borda vermelha se houver um erro */
  background-color: ${(props) => props.theme.inputBackground};
  color: ${(props) => props.theme.inputText};
  transition: box-shadow 0.3s ease, border-color 0.3s ease; /* Adiciona transição para a cor da borda */
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  min-height: 3rem; /* Define a altura mínima */
  max-height: 10rem; /* Define a altura máxima */
  resize: vertical; /* Permite redimensionamento vertical */
  overflow: auto; /* Adiciona barra de rolagem quando necessário */

  &:focus {
    outline: none; /* Remove o contorno padrão do navegador */
    box-shadow: 0 0 0 4px rgba(210, 2, 83, 0.5); /* Adiciona uma sombra ao redor da borda com 50% de opacidade */
  }
`;

export const Placeholder = styled.span<TextareaProps>`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: ${(props) => props.theme.inputPlaceholderColor};
  font-size: 0.9rem;
  pointer-events: none;
  transition: all 0.3s ease;
  user-select: none;

  ${(props) =>
    (props.isFocused || props.hasValue) &&
    css`
      top: 6px;
      font-size: 12px;
      transform: translateY(0);
      color: ${(props) => props.theme.inputFocusBorder};
    `}
`;
