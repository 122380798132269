export enum PeriodOfAppointment {
  MONTH = "month",
  FORTNIGHTLY = "fortnightly",
  WEEK = "week",
  MINUTE = "minute",
  DAY = "day",
}

export enum TypeOfAppointment {
  AVULSO = "avulso",
  RECORRENTE = "recorrente",
}

export interface Price {
  id?: number;
  price: number;
  currency?: "brl" | "eur" | "usd";
  purchaseDetails: {
    // substitui "appointment"
    purchaseType: TypeOfAppointment; // pode mudar para "purchaseType"
    recurrencyPeriod: PeriodOfAppointment; // pode mudar para "recurrencyPeriod"
  };
  isActiveForGroup?: boolean;
}
