import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Crie uma instância do Axios com configurações padrão
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // URL base da sua API
  timeout: 8000, // Tempo máximo de espera por uma resposta (em milissegundos)
  headers: {
    "Content-Type": "application/json", // Tipo de conteúdo das requisições
  },
});

// Interceptor para adicionar o token de autorização às requisições
api.interceptors.request.use(async (config) => {
  const auth = getAuth(); // Obtém a instância do Firebase Auth
  const currentUser = auth.currentUser; // Verifica o usuário atual

  if (currentUser) {
    try {
      // Atualiza o token se necessário
      const idToken = await currentUser.getIdToken(true);
      config.headers.Authorization = `Bearer ${idToken}`;
    } catch (error) {
      console.error("Erro ao atualizar o token:", error);
    }
  }

  return config;
});

// Interceptor para lidar com erros de resposta
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Verifica se o erro foi causado por um token expirado
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error === "auth/id-token-expired"
    ) {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          // Atualiza o token
          const idToken = await currentUser.getIdToken(true);
          localStorage.setItem("idToken", idToken); // Atualiza o token no localStorage

          // Atualiza o cabeçalho da requisição original com o novo token
          originalRequest.headers.Authorization = `Bearer ${idToken}`;

          // Tenta novamente a requisição original
          return axios(originalRequest);
        }
      } catch (error) {
        console.error("Erro ao tentar atualizar o token:", error);
        // Redireciona para login ou outra ação se não conseguir atualizar o token
      }
    }

    return Promise.reject(error); // Retorna o erro se não conseguir renovar o token
  }
);

export default api;
