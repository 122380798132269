import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/buttons";
import { useEffect, useMemo } from "react";
import { SubContainer } from "../../components/container";
import {
  TextLarge,
  TextMedium,
  TextMinimum,
} from "../../components/texts/TextStyles";
import { useTheme } from "styled-components";
import { usePaymentsContext } from "../../context/PaymentsContext";
import { Payment } from "../../types/payments";
import { CardSubscription } from "../../components/cardSubscription";
import { DataSubscription } from "../../types/subscription";
import { formatFirebaseTimestamp } from "../../types/format";
import { SubscriptionsContainer } from "./styles";
import { isUserActive, orderSubscriptions } from "../../utils/subscriptions";
import Loader from "../../components/lottie/loading";

export const ManagerSubscriptions = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { payments, loadingPayments, fetchPayments } = usePaymentsContext();
  const { groupId } = useParams();

  useEffect(() => {
    (() => {
      groupId && fetchPayments({ productId: groupId });
    })();
  }, [groupId]);

  function getUniqueEmailPayments(payments: Payment[]): Payment[] {
    const seenEmails = new Set<string>(); // Conjunto para rastrear e-mails únicos
    const uniquePayments: Payment[] = []; // Array para armazenar pagamentos únicos

    payments?.forEach((payment) => {
      const email = payment.subscription.subscriptionEmail;
      // Se o e-mail ainda não foi visto, adiciona ao conjunto e ao array de pagamentos únicos
      if (!seenEmails.has(email)) {
        seenEmails.add(email);
        uniquePayments.push(payment);
      }
    });

    return uniquePayments; // Retorna o array de pagamentos com e-mails únicos
  }

  // Função para somar os pagamentos internos com status "pago"
  const sumInternalPayments = (
    payments: Payment[],
    type: "pago" | "pendente"
  ): Payment[] => {
    return payments.flatMap(
      (payment) =>
        payment.payments?.filter(
          (item) => item.transactionInfor.status === type
        ) || []
    );
  };

  const sumInternalPaymentsPrice = (
    payments: Payment[],
    type: "pago" | "pendente"
  ): number => {
    return payments
      .flatMap(
        (payment) =>
          payment.payments?.filter(
            (item) => item.transactionInfor.status === type
          ) || []
      )
      .reduce(
        (total, payment) => total + (payment.productInfor?.price?.price || 0),
        0
      );
  };

  // Usando useMemo para memorizar a soma dos pagamentos com status "pago"
  const totalPaid = useMemo(
    () => sumInternalPaymentsPrice(payments, "pago"),
    [payments]
  );

  // Usando useMemo para memorizar a soma dos pagamentos com status "pago"
  const totalPending = useMemo(
    () => sumInternalPaymentsPrice(payments, "pendente"),
    [payments]
  );

  // Usando useMemo para memorizar o total de pagamentos internos com status "pago"
  const totalPaidPayments = useMemo(() => {
    return sumInternalPayments(payments, "pago").length;
  }, [payments]);

  // Usando useMemo para memorizar o total de pagamentos internos com status "pago"
  const totalPendingPayments = useMemo(() => {
    return sumInternalPayments(payments, "pendente").length;
  }, [payments]);

  const getPaymentByUniqueEmail = useMemo(
    () => getUniqueEmailPayments(payments),
    [payments]
  );

  // Função para retornar a última subscription com base no email
  const getLastSubscriptionByEmail = (
    payments: Payment[],
    email: string
  ): DataSubscription | undefined => {
    const subscriptions = payments
      .flatMap((payment) => payment.subscription.subscriptions || []) // Extrai todas as subscriptions
      .filter((subscription) => subscription.emailSubscription === email); // Filtra pelo email

    // Adiciona logs para debug
    console.log("Subscriptions filtradas:", subscriptions);

    const validSubscriptions = subscriptions.filter((subscription) => {
      const endDate = subscription.endDateSubscription;
      // Verifica se endDateSubscription não é null ou undefined e se contém as propriedades necessárias
      const isValidDate =
        endDate &&
        typeof endDate._seconds === "number" &&
        typeof endDate._nanoseconds === "number";

      console.log(`Verificando ${JSON.stringify(endDate)}: ${isValidDate}`);
      return isValidDate;
    });

    // Adiciona mais logs para ver as datas válidas
    console.log("Assinaturas válidas:", validSubscriptions);

    // Ordena pela data de fim (do maior para o menor) e retorna a última
    const lastSubscription = validSubscriptions.sort((a, b) => {
      const dateA = new Date(
        a.endDateSubscription._seconds * 1000 +
          a.endDateSubscription._nanoseconds / 1000000
      ); // Converte para Date
      const dateB = new Date(
        b.endDateSubscription._seconds * 1000 +
          b.endDateSubscription._nanoseconds / 1000000
      ); // Converte para Date
      return dateB.getTime() - dateA.getTime(); // Ordena do maior para o menor
    })[0];

    console.log("Última assinatura:", lastSubscription); // Log para ver a última assinatura

    return lastSubscription; // Retorna a última assinatura válida
  };

  const sumPriceByEmail = (payments: Payment[], email: string) => {
    return sumInternalPayments(payments, "pago")
      .filter((it) => it.subscription.subscriptionEmail === email)
      .reduce((acc, payment) => acc + payment.productInfor.price.price, 0);
  };

  const sortedPaymentsByActiveAndPaymentsCount = getPaymentByUniqueEmail
    ?.map((item: Payment) => {
      // Obtém a última assinatura e formata a data
      const lastSubscription = getLastSubscriptionByEmail(
        payments,
        item.subscription.subscriptionEmail
      );

      const formattedEndDate = lastSubscription
        ? formatFirebaseTimestamp(lastSubscription.endDateSubscription)
        : null;

      // Determina se o usuário está ativo
      const isActive =
        formattedEndDate &&
        isUserActive({
          formattedEndDate: formattedEndDate as string,
        });

      // Conta o número de pagamentos filtrados para este usuário
      const paymentsCount = sumInternalPayments(payments, "pago").filter(
        (it) =>
          it.subscription.subscriptionEmail ===
          item.subscription.subscriptionEmail
      ).length;

      return {
        ...item,
        isActive: !!isActive, // Garante que isActive seja um booleano
        paymentsCount, // Adiciona o número de pagamentos filtrados
      };
    })
    // Ordena primeiro por isActive, depois por paymentsCount
    .sort((a, b) => {
      // Converte isActive para números
      const isActiveA = a.isActive ? 1 : 0;
      const isActiveB = b.isActive ? 1 : 0;

      // Primeiro ordena por isActive (ativos primeiro)
      if (isActiveB !== isActiveA) {
        return isActiveB - isActiveA;
      }

      // Se ambos tiverem o mesmo valor de isActive, ordena pelo número de pagamentos
      return b.paymentsCount - a.paymentsCount;
    });

  const activeUsersCount = useMemo(() => {
    return sortedPaymentsByActiveAndPaymentsCount.filter(
      (item) => item.isActive === true
    ).length;
  }, [sortedPaymentsByActiveAndPaymentsCount]);

  return (
    <div
      style={{
        overflow: "auto", // Permite o scroll
        overflowY: "auto", // Scroll apenas vertical
        height: "100vh",
      }}
    >
      {/* <Button
        onClick={() => navigate(`/dashboard/subscriptions/form/${groupId}`)}
        title={"Adicionar novo assinante"}
      /> */}
      <SubContainer style={{ margin: 24, gap: 12 }}>
        <SubContainer style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 16,
              alignItems: "center",
            }}
          >
            <TextLarge style={{ fontWeight: "800" }}>Clientes Ativos</TextLarge>
            <TextMedium>{activeUsersCount}</TextMedium>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 16,
              alignItems: "center",
            }}
          >
            <TextMedium style={{ fontWeight: "800" }}>
              Pedidos pendentes
            </TextMedium>
            <TextMedium>
              {totalPendingPayments}/R${parseFloat(totalPending?.toFixed(2))}
            </TextMedium>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 16,
              alignItems: "center",
            }}
          >
            <TextMedium style={{ fontWeight: "800" }}>Pedidos pago</TextMedium>
            <TextMedium>
              {totalPaidPayments}/R${parseFloat(totalPaid.toFixed(2))}
            </TextMedium>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 16,
              alignItems: "center",
            }}
          >
            <TextLarge style={{ fontWeight: "800" }}>
              Total de pedidos
            </TextLarge>
            <TextMedium>
              {sumInternalPayments(payments, "pago").length +
                sumInternalPayments(payments, "pendente").length}
              /R$
              {parseFloat(totalPending?.toFixed(2)) +
                parseFloat(totalPaid?.toFixed(2))}
            </TextMedium>
          </div>
        </SubContainer>
        {loadingPayments && (
          <div style={{ height: 200, width: 200, alignSelf: "center" }}>
            <Loader />
          </div>
        )}
        {sortedPaymentsByActiveAndPaymentsCount?.map((item: Payment, index) => {
          // Obtém a última assinatura e formata a data
          const lastSubscription = getLastSubscriptionByEmail(
            item.payments || [],
            item.subscription.subscriptionEmail
          );

          const formattedEndDate = lastSubscription
            ? formatFirebaseTimestamp(lastSubscription.endDateSubscription)
            : null;

          const isActive =
            formattedEndDate &&
            isUserActive({
              formattedEndDate: formattedEndDate as string,
            });

          return (
            <SubContainer
              style={{
                flexDirection: "column",
                display: "flex",
                padding: 24,
                marginBottom: 12,
                width: "100%",
                justifyContent: "flex-start",
                gap: 16,
                alignItems: "flex-start", // Alinhando ao topo quando houver wrap
              }}
              key={index}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <SubContainer
                  style={{
                    backgroundColor: isActive
                      ? theme.greenLight
                      : theme.containerBackground,
                    padding: 12,
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: 200,
                  }}
                >
                  <TextMinimum
                    style={{
                      color: isActive
                        ? theme.greenText
                        : theme.inputFocusBorder,
                      fontWeight: "800",
                    }}
                  >
                    {isActive ? "Ativo" : "Expirado"}
                  </TextMinimum>
                  <TextMinimum
                    style={{
                      color: isActive
                        ? theme.greenText
                        : theme.inputFocusBorder,
                      fontWeight: "800",
                    }}
                  >
                    últ. {formattedEndDate}
                  </TextMinimum>
                </SubContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center", // Garante que os itens de cada lado estejam alinhados ao topo
                    justifyContent: "flex-start",
                    gap: 16,
                    width: "100%",
                    flexWrap: "wrap",
                    marginLeft: 12, // Ajusta o espaçamento entre os blocos
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 6,
                      flex: 1,
                    }}
                  >
                    <TextMinimum style={{ color: theme.textGray }}>
                      {item?.subscription?.subscriptionEmail}
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Qtd Renovação
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      {
                        sumInternalPayments(payments, "pago").filter(
                          (it) =>
                            it.subscription.subscriptionEmail ===
                            item.subscription.subscriptionEmail
                        ).length
                      }
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Receita
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      R$
                      {parseFloat(
                        sumPriceByEmail(
                          payments,
                          item.subscription.subscriptionEmail
                        )?.toFixed(2)
                      )}
                    </TextMinimum>
                  </div>
                </div>
              </div>
              <SubscriptionsContainer>
                {item?.subscription?.subscriptions &&
                  orderSubscriptions({
                    subscriptions: item.subscription.subscriptions,
                  }).map((subscriptionData) => {
                    return (
                      <CardSubscription
                        key={subscriptionData.transactionId}
                        subscription={subscriptionData}
                        payments={payments}
                      />
                    );
                  })}
              </SubscriptionsContainer>
              {/* <CardSubscription subscription={undefined} payment={undefined}/> */}
            </SubContainer>
          );
        })}
      </SubContainer>
    </div>
  );
};
