import { useEffect } from "react";
import Toggle from "../../toggle";
import { SubContainer } from "../../container";
import { usePricesContext } from "../../../context/PricesContext";
import { FormatNamePrice } from "../../../utils/formatNamePrice";
import Loader from "../../lottie/loading";

export const ListPrecos = ({ groupId }: { groupId: number }) => {
  const { prices, fetchPrices, updatePriceItem, loadingPrices } =
    usePricesContext();

  useEffect(() => {
    fetchPrices({ groupId });
  }, [groupId]);

  return (
    <div
      style={{
        height: "250px", // Defina a altura desejada para o contêiner
        overflowY: "auto", // Permite o scroll vertical
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minWidth: "100%",
        marginTop: 24
      }}
    >
      {loadingPrices && <Loader />}
      {prices
        .sort((a, b) => b.price - a.price)
        .map((item, index) => (
          <SubContainer
            key={index}
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ flexDirection: "column", display: "flex" }}>
              <label>R$ {item.price}</label>
              <label>{item.purchaseDetails.purchaseType}</label>
              {item.purchaseDetails.purchaseType === "recorrente" && (
                <label>
                  {FormatNamePrice(item.purchaseDetails.recurrencyPeriod)}
                </label>
              )}
            </div>
            <Toggle
              initialState={item.isActiveForGroup}
              onToggle={() =>
                updatePriceItem(index, groupId, {
                  ...item,
                  isActiveForGroup: !item.isActiveForGroup,
                })
              }
            />
          </SubContainer>
        ))}
    </div>
  );
};
