import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { paymentsApi } from "../services/payments";
import { Payment } from "../types/payments";

interface PaymentsContextType {
  payments: Payment[];
  loadingPayments: boolean;
  fetchPayments: ({ productId }: { productId: string }) => Promise<void>;
}

// Crie o contexto com um valor padrão (vazio)
const PaymentsContext = createContext<PaymentsContextType>({
  payments: [],
  loadingPayments: false,
  fetchPayments: async ({ productId }: { productId: string }) => {},
});

// Crie um provedor de contexto
export const PaymentsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loadingPayments, setLoadingPayments] = useState(false);

  // Função para buscar pagamentos
  const fetchPayments = useCallback(
    async ({ productId }: { productId: string }) => {
      setLoadingPayments(true);
      try {
        const fetchedPayments = await paymentsApi.getPaymentsByProductId({
          productId,
        });
        console.log({...fetchedPayments})
        setPayments(fetchedPayments || []);
      } catch (error) {
        console.log(error);
        toast.error("Erro ao buscar pagamentos.");
      } finally {
        setLoadingPayments(false);
      }
    },
    []
  );

  return (
    <PaymentsContext.Provider
      value={{
        payments,
        loadingPayments,
        fetchPayments,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

// Hook personalizado para usar o contexto
export const usePaymentsContext = () => {
  const context = useContext(PaymentsContext);
  if (context === undefined) {
    throw new Error(
      "usePaymentsContext must be used within a PaymentsProvider"
    );
  }
  return context;
};
