import { useCallback, useEffect, useState } from "react";
import Toggle from "../../components/toggle";
import { ContainerParent } from "../../components/container/ContainerParent";
import { useTheme } from "styled-components";
import { useBotContext } from "../../context/BotContext";
import { groupApi } from "../../services/groups";
import { TelegramGroups } from "../../types/telegram-groups";
import { Header } from "../../components/header";
import { useAuth } from "../../context/AuthContext";
import { SubContainer } from "../../components/container";
import { TextLarge } from "../../components/texts/TextStyles";
import PriceModal, { Values } from "../../components/modal/price";
import { usePricesContext } from "../../context/PricesContext";
import { toast } from "react-toastify";
import { CardGrupo } from "../../components/cardGrupo";
import Loader from "../../components/lottie/loading";

export const ManagerGroups = () => {
  const theme = useTheme();
  const [isModalPriceOpen, setIsModalPriceOpen] = useState({
    isOpen: false,
    groupId: 0,
  });
  const { isActive, startBot, stopBot } = useBotContext();
  const [grupos, setGrupos] = useState<TelegramGroups[]>([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const { addPriceItem, loadingSavePrice } = usePricesContext();
  const [formActive, setFormActive] = useState(false);
  const [loadingRemoveDescription, setLoadingRemoveDescription] = useState(false);
  const { user } = useAuth();

  const getGroups = useCallback(async () => {
    try {
      setLoadingGroups(true);
      const grupos = await groupApi.getGroups();
      setGrupos(grupos);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingGroups(false);
    }
  }, []);

  useEffect(() => {
    getGroups();
  }, []);

  const setTempGroup = async ({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }) => {
    console.log({
      groupId,
      tempValue,
    });
    try {
      await groupApi.setStatusTempGroup({
        groupId,
        tempValue,
      });
      const newGroups = grupos.map((group) => {
        if (group.groupInfor.groupId === groupId) {
          // Clona o grupo e altera o valor de "temp"
          return {
            ...group,
            groupInfor: {
              ...group.groupInfor,
              temp: tempValue,
            },
          };
        }
        return group; // Retorna o grupo sem alterações se o ID não corresponder
      });
      setGrupos(newGroups);
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar transformar grupo em temporário");
    }
  };

  const setIsActiveGroup = async ({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }) => {
    console.log({
      groupId,
      tempValue,
    });
    try {
      await groupApi.setIsActiveGroup({
        groupId,
        tempValue,
      });
      const newGroups = grupos.map((group) => {
        if (group.groupInfor.groupId === groupId) {
          // Clona o grupo e altera o valor de "temp"
          return {
            ...group,
            groupInfor: {
              ...group.groupInfor,
              isActive: tempValue,
            },
          };
        }
        return group; // Retorna o grupo sem alterações se o ID não corresponder
      });
      setGrupos(newGroups);
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar transformar grupo em temporário");
    }
  };

  const removeDescription = async ({
    description,
    groupId,
  }: {
    description: { url?: string; text?: string };
    groupId: number;
  }) => {
    try {
      setLoadingRemoveDescription(true)
      await groupApi.removeDescription({ description, groupId });
      getGroups();
      toast.success("Descrição removida com sucesso!");
    } catch (error) {
      console.log(error);
    } finally{
      setLoadingRemoveDescription(false)
    }
  };

  return (
    <ContainerParent
      style={{
        overflow: "auto",
        backgroundColor: theme.subContainerBackground,
        display: "flex",
        flexDirection: "column",
        padding: 18,
      }}
    >
      <Header username={user?.displayName?.toString() || ""} />
      {loadingGroups && (
        <div
          style={{
            maxHeight: 150,
            maxWidth: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            alignSelf: "center",
          }}
        >
          <Loader />
        </div>
      )}
      <SubContainer
        style={{
          flex: 1,
          width: "100%",
          marginTop: 24,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          boxSizing: "border-box",
          gap: 24,
        }}
      >
        <TextLarge style={{ fontWeight: "800" }}>
          Gerencie seus grupos VIP do telegram
        </TextLarge>
        <SubContainer style={{ width: "100%", marginBottom: 24 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Ativar/Desativar Bot</span>
            <Toggle
              initialState={isActive}
              onToggle={() => {
                if (!isActive) {
                  startBot();
                } else {
                  stopBot();
                }
              }}
            />
          </div>
        </SubContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "start",
            flexWrap: "wrap",
            gap: 12,
            overflow: "auto",
            height: "auto",
            backgroundColor: "transparent",
          }}
        >
          {grupos &&
            grupos?.map((item, index) => {
              return (
                <CardGrupo
                  removeDescription={removeDescription}
                  loadingRemoveDescription={loadingRemoveDescription}
                  setIsModalPriceOpen={() =>
                    setIsModalPriceOpen({
                      isOpen: true,
                      groupId: item.groupInfor.groupId,
                    })
                  }
                  setIsActiveGroup={() =>
                    setIsActiveGroup({
                      groupId: item.groupInfor.groupId,
                      tempValue: !item?.groupInfor?.isActive,
                    })
                  }
                  setTempGroup={() =>
                    setTempGroup({
                      groupId: item.groupInfor.groupId,
                      tempValue: !item?.groupInfor?.temp,
                    })
                  }
                  temp={Boolean(item?.groupInfor?.temp)}
                  groupId={item.groupInfor.groupId}
                  getGroups={getGroups}
                  description={
                    item.groupInfor?.description as {
                      url?: string;
                      text?: string;
                    }
                  }
                  title={item.groupInfor.title}
                  isActive={Boolean(item?.groupInfor?.isActive)}
                />
              );
            })}
        </div>
      </SubContainer>
      <PriceModal
        formActive={formActive}
        setFormActive={setFormActive}
        loadingSavePrice={loadingSavePrice}
        isOpen={isModalPriceOpen.isOpen}
        onClose={() => setIsModalPriceOpen({ isOpen: false, groupId: 0 })}
        priceDetails={{
          groupId: Number(isModalPriceOpen.groupId),
        }}
        onSave={(data: Values) => {
          addPriceItem(data, isModalPriceOpen.groupId);
          setFormActive(false);
        }}
      />
    </ContainerParent>
  );
};
