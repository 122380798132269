import axios from "axios";
import api from "../conections/axios";

export const botApi = {
  async checkStatus() {
    try {
      const response = await api.get(`/telegram/webhook-status`);
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
  async startBot() {
    try {
      let response = await api.post(`/telegram/start-bot`);
      if (process.env.NODE_ENV === "development") {
        response = await axios.post(
          `https://2d81-2804-29b8-5015-4c7c-f45c-f6ec-7127-f443.ngrok-free.app/telegram/start-bot`
        );
      }
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
  async stopBot() {
    try {
      const response = await api.post(`/telegram/stop-bot`);
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
};
