import styled from "styled-components";

export const SubscriptionsContainer = styled.div` 
  margin-top: 24px;
  display: flex; /* Flexbox para alinhamento horizontal */
  overflow-x: auto; /* Permite rolagem horizontal */
  white-space: nowrap; /* Impede que os itens quebrem para a próxima linha */
  padding: 10px; /* Espaçamento interno opcional */
  max-width: 100%; /* Limita a largura do contêiner a 100% do seu pai */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra opcional */
  border-radius: 5px; /* Bordas arredondadas opcionais */
  gap: 16px;

  /* Oculta a barra de rolagem */
  &::-webkit-scrollbar {
    display: none; /* Para navegadores baseados em WebKit (Chrome, Safari) */
  }

  /* Para Firefox */
  scrollbar-width: none; /* Oculta a barra de rolagem no Firefox */

  /* Se você quiser manter a barra visível em dispositivos de toque */
  touch-action: pan-x; /* Permite o movimento de rolagem horizontal */
`;
