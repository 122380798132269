// IntegrationContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";
import { integrationApi } from "../services/integration";
import { Platform, PlatformTokenMap } from "../types/integration";
import { toast } from "react-toastify";
import {
  AuthTelegramInforResponse,
  SignInTelegramInfor,
} from "../types/telegram-signin";

// Defina os tipos para o contexto
interface IntegrationContextType {
  tokens: PlatformTokenMap;
  botInfor: AuthTelegramInforResponse | null;
  fetchTokens: () => Promise<void>;
  saveTokenDefault: (p: Platform, t: string) => Promise<void>;
  updateUserInforTelegram: (p: SignInTelegramInfor) => Promise<void>;
  getInforBotTelegram: (p: string) => Promise<void>;
}

// Crie o contexto com um valor padrão (vazio)
const IntegrationContext = createContext<IntegrationContextType>({
  tokens: {},
  botInfor: null,
  fetchTokens: async () => {},
  saveTokenDefault: async () => {},
  updateUserInforTelegram: async () => {},
  getInforBotTelegram: async () => {},
});

// Crie um provedor de contexto
export const IntegrationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tokens, setTokens] = useState<PlatformTokenMap>({});
  const [botInfor, setBotInfor] = useState<AuthTelegramInforResponse | null>(
    null
  );

  // Função para buscar tokens
  const fetchTokens = async () => {
    try {
      const data = await integrationApi.getAllTokens(["telegram", "mp"]);
      setTokens(data);
    } catch (error) {
      console.log(error);
      //toast.error("Erro ao buscar os tokens de acesso!");
    }
  };

  const saveTokenDefault = async (platform: Platform, token: string) => {
    try {
      await integrationApi.saveToken(platform, token);
      toast.success(`Token ${platform} salvo com sucesso!`);
    } catch (error) {
      toast.error(`Erro ao tentar salvar token ${platform}!`);
    }
  };

  const getInforBotTelegram = async (botToken: string) => {
    try {
      if (!botToken) {
        return;
      }
      const response = await integrationApi.getInforTelegram(botToken);
      setBotInfor(response);
      return;
    } catch (error) {
      toast.error(`Erro ao tentar buscar informações do bot!`);
      return;
    }
  };

  const updateUserInforTelegram = async (props: SignInTelegramInfor) => {
    try {
      await integrationApi.updateInforUserTelegram(props);
      toast.success(`Atualizado com sucesso!`);
    } catch (error) {
      toast.error(`Erro ao tentar atualizar dados de administrador Telegram!`);
    }
  };

  return (
    <IntegrationContext.Provider
      value={{
        tokens,
        botInfor,
        fetchTokens,
        saveTokenDefault,
        updateUserInforTelegram,
        getInforBotTelegram,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

// Hook personalizado para usar o contexto
export const useIntegrationContext = () => {
  const context = useContext(IntegrationContext);
  if (context === undefined) {
    throw new Error("useIntegrationsContext within an AuthProvider");
  }
  return context;
};
