import React, { useState } from "react";
import { InputWrapper, Placeholder, StyledInput } from "./styledComponents";
import { InputFieldProps } from ".";

export const InputFieldIndependent: React.FC<InputFieldProps> = ({
  placeholder,
  name,
  type,
  as,
  value = "",
  onChange,
  style,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputWrapper style={{ ...style }}>
      <StyledInput
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          onBlur && onBlur();
        }}
        isFocused={isFocused}
        hasValue={!!value}
        {...props}
      />
      <Placeholder hasValue={!!value} isFocused={isFocused}>
        {placeholder}
      </Placeholder>
    </InputWrapper>
  );
};
