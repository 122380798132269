import { Form, Field, ErrorMessage } from "formik";
import styled, { useTheme } from "styled-components";
import Button from "../buttons";
import { priceSchema } from "../../shemas/price";
import { formPrice } from "../../forms/prices";
import { FormPrice } from "../form/formPrice";
import { ListPrecos } from "../form/formPrice/listPrices";
import { PeriodOfAppointment, TypeOfAppointment } from "../../types/prices";

interface PriceModalProps {
  isOpen: boolean;
  formActive: boolean;
  loadingSavePrice: boolean;
  setFormActive: (v: boolean) => void;
  onClose: () => void;
  priceDetails?: {
    groupId: number;
  };
  onSave: (values: typeof initialValues) => void;
}

export interface Values {
  priceId: string;
  currency: string;
  frequency: PeriodOfAppointment;
  price: number;
  purchaseType: TypeOfAppointment;
}

// Valores iniciais do formulário
const initialValues: Values = {
  priceId: "",
  currency: "BRL",
  frequency: PeriodOfAppointment.DAY,
  price: 97,
  purchaseType: TypeOfAppointment.AVULSO,
};

const PriceModal = ({
  isOpen,
  onClose,
  priceDetails,
  loadingSavePrice,
  formActive,
  setFormActive,
  onSave,
}: PriceModalProps) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose(); // Fecha o modal ao clicar fora
        }
      }}
    >
      <ModalContent>
        {formActive && (
          <>
            <FormPrice
              initialValues={{
                frequency: "day",
                purchaseType: "avulso",
                typePayment: "pix",
                currency: "brl",
              }}
              Shema={priceSchema}
              form={formPrice}
              titleButtonAction="Salvar Preço"
              title={"Adicionar Preço"}
              loading={loadingSavePrice}
              onSubmit={onSave}
            />
          </>
        )}
        {!formActive && (
          <Button
            style={{ width: "100%" }}
            title="Cadastrar novo preço"
            onClick={() => setFormActive(!formActive)}
          />
        )}
        <ListPrecos groupId={Number(priceDetails?.groupId)} />
        {/* <Button
          style={{
            width: "100%",
            marginTop: 16,
            backgroundColor: theme.textGray,
          }}
          title={"Fechar"}
          onClick={onClose}
        /> */}
      </ModalContent>
    </ModalOverlay>
  );
};

export default PriceModal;

// Estilos com styled-components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;
