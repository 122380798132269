import api from "../conections/axios";
import { TelegramGroups } from "../types/telegram-groups";

export const groupApi = {
  async getGroups(): Promise<TelegramGroups[]> {
    try {
      const response = await api.get(`/grupos`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getStatusTempGroup({ groupId }: { groupId: number }): Promise<Boolean> {
    try {
      const response = await api.get(`/grupos/get-temp?groupId=${groupId}`);
      return response.data?.temp;
    } catch (error) {
      throw error;
    }
  },
  async setStatusTempGroup({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }): Promise<Boolean> {
    try {
      const response = await api.post(`/grupos/update-temp`, {
        tempValue,
        groupId,
      });
      return response.data?.temp;
    } catch (error) {
      throw error;
    }
  },
  async setDescription({ formData }: { formData: FormData }): Promise<Boolean> {
    try {
      const response = await api.post(`/grupos/update-description`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Se necessário, mas pode ser omitido
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async removeDescription({
    groupId,
    description,
  }: {
    groupId: number;
    description: { text?: string; url?: string };
  }): Promise<Boolean> {
    try {
      const response = await api.post(`/grupos/remove-description`, {
        groupId,
        description,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async setIsActiveGroup({
    groupId,
    tempValue,
  }: {
    groupId: number;
    tempValue: boolean;
  }): Promise<Boolean> {
    try {
      const response = await api.post(`/grupos/update-active`, {
        tempValue,
        groupId,
      });
      return response.data?.temp;
    } catch (error) {
      throw error;
    }
  },
};
