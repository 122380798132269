import { Payment } from "../types/payments";
import { DataSubscription } from "../types/subscription";
import { parse, isAfter, isEqual, format } from "date-fns";

// Função para retornar a última subscription com base no email
export const getLastSubscriptionByEmail = ({
  payments,
  email,
}: {
  payments?: Payment[];
  email: string;
}): DataSubscription | undefined => {
  return payments
    ?.flatMap((payment) => payment?.subscription?.subscriptions || []) // Extrai todas as subscriptions
    .filter((subscription) => subscription?.emailSubscription === email) // Filtra pelo email
    .sort(
      (a, b) =>
        new Date(b?.endDateSubscription).getTime() -
        new Date(a?.endDateSubscription).getTime()
    )[0]; // Ordena pela data de fim e retorna a última
};

// Função para verificar se o usuário ainda está ativo
export const isUserActive = ({
  formattedEndDate,
}: {
  formattedEndDate?: string;
}): boolean => {
  const dateToCompare = parse(
    formattedEndDate?.toString() as string,
    "dd/MM/yyyy HH:mm",
    0
  ); // Passa 0 como base, mas será sobrescrito

  // Função para obter a data atual formatada (sem usar new Date diretamente no código)
  const currentDateStr = format(new Date(), "dd/MM/yyyy HH:mm");
  const currentDate = parse(currentDateStr, "dd/MM/yyyy HH:mm", 0);

  // Verifica se a data é maior ou igual à data atual
  const isGreaterOrEqual =
    isAfter(dateToCompare, currentDate) || isEqual(dateToCompare, currentDate);
  return isGreaterOrEqual;
};

export const orderSubscriptions = ({
  subscriptions,
}: {
  subscriptions?: DataSubscription[];
}) => {
  console.log("orderSubscriptions => ", { ...subscriptions });
  const sortedSubscriptions = subscriptions?.sort((a, b) => {
    // Desestruturação dos valores de _seconds e _nanoseconds
    const endDateA = a?.endDateSubscription;
    const endDateB = b?.endDateSubscription;

    // Converte os timestamps para milissegundos
    const dateA =
      endDateA?._seconds * 1000 + Math.floor(endDateA?._nanoseconds / 1000000);
    const dateB =
      endDateB?._seconds * 1000 + Math.floor(endDateB?._nanoseconds / 1000000);

    // Compara as datas
    return dateB - dateA; // Para ordem decrescente
  });
  return sortedSubscriptions || [];
};
