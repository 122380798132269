import { InputFieldProps } from "../components/Input";

export const formPrice: InputFieldProps[] = [
  {
    placeholder: "Preço (R$)",
    type: "numeric",
    name: "price",
  },
  // {
  //   placeholder: "Moeda",
  //   type: "select",
  //   name: "currency", // Nome do campo que será enviado no formulário
  //   options: [{ value: "brl", label: "Real (R$)" }],
  // },
  {
    placeholder: "Tipo de venda",
    type: "select",
    name: "purchaseType", // Nome do campo que será enviado no formulário
    options: [
      { value: "avulso", label: "Avulso" },
      { value: "recorrente", label: "Recorrência" },
    ],
  },
  {
    placeholder: "Frequência da recorrência",
    type: "select",
    name: "frequency", // Nome do campo que será enviado no formulário
    options: [
      { value: "minute", label: "2 minutos" },
      { value: "day", label: "1 dia" },
      { value: "week", label: "7 dias" },
      { value: "fortnightly", label: "15 dias" },
      { value: "month", label: "30 dias" },
    ],
  },
  {
    placeholder: "Forma de pagamento",
    type: "select",
    name: "typePayment", // Nome do campo que será enviado no formulário
    options: [{ value: "pix", label: "PIX" }],
  },
];
