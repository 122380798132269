import React, { useRef } from "react";
import "./CheckoutForm.css"; // Importando o arquivo CSS com estilos customizados
import { useTrail, animated } from "@react-spring/web";
import Button from "../buttons";
import CheckoutButton from "../buttons/CheckoutButton";
import Lottie from "lottie-react";
import love from "./love.json"; // Substitua pelo caminho do seu arquivo JSON de animação
import orlGif from "./video1.gif";
import socialInstagram from "../../assets/instagram.png";
import closed from "../../assets/fechadas.png";
import amorIcon from "../../assets/dar-amor.png";
import bocaIcon from "../../assets/boca.png";
import chicoteIcon from "../../assets/chicote.png";
import { eventAnalytics } from "../../firebase/events/analytics";
import useRemoteConfigValue from "../../hooks/remoteConfig";

const Page: React.FC = () => {
  const isActiveForBuy = useRemoteConfigValue("isActiveForBuy");
  const options = [
    { id: 1, title: "Todos os Meus Vídeos HOT" },
    { id: 0, title: "Vídeos Tr4ns4ndo" },
    {
      id: 2,
      title: "Live HOT no Grupo",
    },
    {
      id: 3,
      title: "Live HOT com minhas amigas no Grupo",
    },
  ];

  const exclusive = [
    { id: 4, title: "Ligação de Voz a Qualquer Hora no Grupo" },
    { id: 5, title: "Conversas Carinhosas e Pic4ntes no Privado" },
    { id: 0, title: "Minha Rotina Diária" },
    { id: 2, title: "Apresentação da Minha Amiga" },
    { id: 3, title: "1 Foto Personalizada individual por Semana" },
  ];

  const bonus = [
    {
      id: 1,
      title: "Avaliação Do Pirulito em Chamada de Vídeo (20 primeiros)",
    },
    { id: 2, title: "Vou enviar um vídeo para você me avaliar 😏" },
    { id: 3, title: "1 Foto Personalizada individual por Semana" },
    { id: 4, title: "Realizar um pedido de Fetiche" },
  ];

  const trails = useTrail(options.length, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
  });

  const trailsExclusive = useTrail(exclusive.length, {
    from: { opacity: 0, transform: "translate3d(-40px,0,0)" },
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
  });

  const trailsBonus = useTrail(bonus.length, {
    from: { opacity: 0, transform: "translate3d(-40px,0,0)" },
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
  });

  const checkoutRef = useRef<HTMLDivElement>(null);

  const handleScrollToCheckout = () => {
    eventAnalytics("subscribe_first", {});
    checkoutRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container">
      <div className="subContainer">
        <div className="options">
          <h1 style={{ marginTop: 88 }}>
            O QUE VOCÊ VAI VER NO MEU GRUPO VIP?
          </h1>
          <div className="option-list">
            {trails.map((style, index) => (
              <animated.div
                key={options[index].id}
                className="option"
                style={style}
              >
                <img
                  className="options-icon"
                  src={bocaIcon}
                  alt="boca social"
                />
                <span>{options[index].title}</span>
              </animated.div>
            ))}
          </div>
          <h1>EXCLUSIVO DO GRUPO VIP</h1>
          <div className="option-list">
            {trailsExclusive.map((style, index) => (
              <animated.div
                key={exclusive[index].id}
                className="option"
                style={style}
              >
                <img className="options-icon" src={amorIcon} alt="amor icon" />
                <span>{exclusive[index].title}</span>
              </animated.div>
            ))}
          </div>
          <div className="pulsing-button ">
            {" "}
            <Button
              style={{ marginTop: 48, marginBottom: 48, alignSelf: 'center' }}
              title={"QUERO ASSINAR O GRUPO VIP"}
              onClick={handleScrollToCheckout}
            />
          </div>

          <h1>BÔNUS PARA ASSINANTES</h1>
          <div className="option-list">
            {trailsBonus.map((style, index) => (
              <animated.div
                key={bonus[index]?.id}
                className="option"
                style={style}
              >
                <img
                  className="options-icon"
                  src={chicoteIcon}
                  alt="chicote icon"
                />
                <span>{bonus[index]?.title}</span>
              </animated.div>
            ))}
          </div>
          <div
            ref={checkoutRef}
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 48,
              paddingBottom: 200,
            }}
          >
            {isActiveForBuy ? (
              <h1>Grupo VIP aberto para ser meu amigo Íntimo!</h1>
            ) : (
              <h1>Aguardando Orlandina abrir o Grupo VIP...</h1>
            )}
            {!isActiveForBuy && (
              <img className="closed-icon" src={closed} alt="closed" />
            )}
            <CheckoutButton />
            <span style={{ marginTop: 24 }}>
              Após o Pagamento Você Receberá o Acesso ao Grupo VIP da Orlandina
              no Telegram, Qualquer dúvida Entre em Contato com a Princesa
              Orlandina. 👸🏽
            </span>
            <span style={{ marginTop: 24 }}>
              O pagamento é realizado por recorrência automaticamente em sua
              fatura, caso não seja renovado a recorrência você perderá acesso
              automaticamente ao Grupo Vip. WhatsApp para suporte: +55 88
              99246-6128
            </span>
          </div>
        </div>
      </div>
      <Lottie
        animationData={love}
        loop={true} // Defina como true se deseja que a animação seja reproduzida em loop
        autoplay={true} // Defina como true se deseja que a animação comece automaticamente
        style={{
          height: 1200,
          position: "absolute",
          bottom: 0,
          width: "auto",
          zIndex: -1,
          right: 80,
          minWidth: "auto",
          opacity: 0.7
        }} // Defina o tamanho da animação conforme necessário
      />
      <Lottie
        animationData={love}
        loop={true} // Defina como true se deseja que a animação seja reproduzida em loop
        autoplay={true} // Defina como true se deseja que a animação comece automaticamente
        style={{
          height: 1200,
          position: "absolute",
          width: "auto",
          minWidth: "auto",
          left: 200,
          zIndex: -1,
          bottom: -800,
          opacity: 0.7,
          
        }} // Defina o tamanho da animação conforme necessário
      />

      <div className="video-container">
        <img className="video" src={orlGif} alt="GIF Orlandina" />
      </div>
      <div
        onClick={() => {
          window.location.href = "https://www.instagram.com/fifi_maria2/";
        }}
        className="social-container"
      >
        <img className="social-icon" src={socialInstagram} alt="icon social" />
      </div>
    </div>
  );
};

export default Page;
