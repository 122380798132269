import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { toDate } from "date-fns-tz"; // Verifica a função disponível para a versão que você usa

// Fuso horário de São Paulo (mesmo fuso horário de Brasília)
const timeZone = "America/Sao_Paulo";

// Função que converte o Firebase Timestamp em milissegundos e formata a data no fuso horário de São Paulo
export const formatFirebaseTimestamp = (timestamp: {
  _seconds: number;
  _nanoseconds: number;
}): string => {
  // Converte o timestamp do Firebase para milissegundos
  const milliseconds =
    timestamp._seconds * 1000 + Math.floor(timestamp._nanoseconds / 1000000);

  // Converte para o fuso horário de São Paulo (ajusta para o horário correto)
  const zonedDate = toDate(new Date(milliseconds), { timeZone });

  // Formata a data no fuso horário de São Paulo
  return format(zonedDate, "dd/MM/yyyy HH:mm", { locale: ptBR });
};
