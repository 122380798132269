import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "../pages/SignIn";
// import Galery from "../pages/Galery";
import { useEffect, useState } from "react";
import { auth } from "../firebase";
import LandPage from "../pages/landPage";
import Dashboard from "../pages/Dashboard";
import { useNavigate } from "react-router-dom";
import { ManagerGroups } from "../pages/ManagerGroups";
import { ManagerSubscriptions } from "../pages/ManagerSubscriptions";
import { FormSubscription } from "../pages/ManagerSubscriptions/addSubscriptionForm";
import { OptionsGroup } from "../pages/ManagerGroups/OptionsGroup";
import { SucessPayment } from "../pages/landPage/SucessPayment";
import FormProduct from "../pages/ManagerProducts/FormProduct";
import { ManagerPrices } from "../pages/ManagerPrices";
import FormPrice from "../pages/ManagerPrices/FormPrice";
// import Home from "../pages/Home";

const PrivateRoute = ({ Page }: any) => {
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Usuário autenticado
        console.log("Usuário autenticado:", user);
        setLogged(true);
      } else {
        // Usuário não autenticado (deslogado)
        console.log("Usuário deslogado");
        setLogged(false); // Redireciona para a tela de login
        navigate("/entrar");
      }
    });

    return () => unsubscribe();
  }, []);

  return !logged ? <Login /> : <Page />;
};

const RouteNavigation = () => {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Routes>
        <Route path="/" element={<PrivateRoute Page={Dashboard} />} />
        <Route path="/entrar" element={<Login />} />
        {/* <Route path="/galeriasecreta/:username" element={<Galery />} /> */}
        <Route path="/page/:nickname" element={<LandPage />} />
        <Route path="/page/successPayment" element={<SucessPayment />} />
        {/* <Route path="/home/:username" element={<PrivateRoute Page={Home} />} /> */}
        <Route path="/dashboard" element={<PrivateRoute Page={Dashboard} />} />
        <Route
          path="/dashboard/integrations"
          element={<PrivateRoute Page={Dashboard} />}
        />
        <Route
          path="/groups/:nickname"
          element={<PrivateRoute Page={ManagerGroups} />}
        />
        <Route
          path="/dashboard/products"
          element={<PrivateRoute Page={Dashboard} />}
        />
        <Route
          path="/products/createProduct/:groupId"
          element={<PrivateRoute Page={FormProduct} />}
        />
        <Route
          path="/products/prices"
          element={<PrivateRoute Page={ManagerPrices} />}
        />
        <Route
          path="/products/prices/createPrice/:productId"
          element={<PrivateRoute Page={FormPrice} />}
        />
        <Route
          path="/subscriptions/:groupId"
          element={<PrivateRoute Page={ManagerSubscriptions} />}
        />
        <Route
          path="/options/:groupId"
          element={<PrivateRoute Page={OptionsGroup} />}
        />
        <Route
          path="/subscriptions/form/:groupId"
          element={<PrivateRoute Page={FormSubscription} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteNavigation;
