// TextComponents.tsx
import Text from "./TextBase";

export const TextMinimum: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void; // Adiciona a propriedade onClick como opcional
}> = (props) => <Text size="12px" {...props} />;

export const TextMinimumMedium: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = (props) => <Text size="14px" {...props} />;

export const TextMedium: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = (props) => <Text size="16px" {...props} />;

export const TextLarge: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = (props) => <Text size="20px" {...props} />;
