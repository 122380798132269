import api from "../conections/axios";
import { Price } from "../types/prices";

export const pricesApi = {
  async getPrices(): Promise<Price[]> {
    try {
      const response = await api.get(`/price/all`);
      return response.data?.prices;
    } catch (error) {
      throw error;
    }
  },
  async getPricesForGroup({ groupId }: { groupId: number }): Promise<Price[]> {
    try {
      const response = await api.get(`/price/get-for-group?groupId=${groupId}`);
      return response.data?.prices;
    } catch (error) {
      throw error;
    }
  },
  async removePriceFromGroup({
    groupId,
    priceId,
  }: {
    groupId: number;
    priceId: string;
  }): Promise<void> {
    try {
      const response = await api.delete(`/price/remove-from-group`, {
        data: { groupId, priceId },
      });
      if (response.status === 200) {
        console.log(
          `Preço com ID ${priceId} removido do grupo ${groupId} com sucesso.`
        );
      }
    } catch (error) {
      console.error(`Erro ao tentar remover o preço do grupo:`, error);
      throw error; // Repassa o erro para ser tratado onde a função for chamada
    }
  },
  async savePriceToGroup({
    groupId,
    priceId,
  }: {
    groupId: number;
    priceId: string;
  }): Promise<void> {
    try {
      const response = await api.post(`/price/save-for-group`, {
        groupId,
        priceId,
      });
      if (response.status === 200) {
        console.log(
          `Preço com ID ${priceId} adicionado ao grupo ${groupId} com sucesso.`
        );
      }
    } catch (error) {
      console.error(`Erro ao tentar salvar o preço no grupo:`, error);
      throw error; // Repassa o erro para ser tratado onde a função for chamada
    }
  },
  async savePrice(price: Price): Promise<void> {
    try {
      const response = await api.post(`/price/save`, { ...price });
      if (response.status === 200) {
        console.log(`Preço adicionado com sucesso.`);
        return response.data;
      }
    } catch (error) {
      console.error(`Erro ao tentar salvar o preço no grupo:`, error);
      throw error; // Repassa o erro para ser tratado onde a função for chamada
    }
  },
};
