import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputField, InputFieldProps } from "../../Input";
import Button from "../../buttons";

interface FormPriceProps {
  form: InputFieldProps[];
  title: string;
  titleButtonAction: string;
  Shema?: Yup.AnySchema | any;
  loading: boolean;
  initialValues: Record<string, string>;
  onSubmit: (values: any) => void;
}

export const FormPrice: React.FC<FormPriceProps> = ({
  form,
  titleButtonAction,
  Shema,
  loading,
  initialValues,
  onSubmit,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <h1>{title}</h1> */}
      <Formik
        initialValues={initialValues}
        validationSchema={Shema}
        onSubmit={(v, { resetForm }) => {
          onSubmit(v);
          resetForm();
        }}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, isValid, setFieldValue, values }) => {
          return (
            <Form className="conteiner-form-price">
              {form.map((item: any) => {
                return (
                  <div
                    key={item.name}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.type === "select" &&
                    item.name === "frequency" &&
                    values.purchaseType === "avulso" ? null : item.type ===
                        "select" && item.options ? (
                      <div className="select-container">
                        <span>{item.placeholder}</span>
                        <select
                          id={item.name}
                          name={item.name}
                          value={values[item.name] || ""}
                          onChange={(e) => {
                            setFieldValue(item.name, e.target.value);
                          }}
                        >
                          {item.options.map((option: any, index: any) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div style={{ marginBottom: 16 }}>
                        <InputField
                          key={item.name}
                          placeholder={item.placeholder}
                          type={item.type}
                          name={item.name}
                        />
                      </div>
                    )}
                    {errors[item.name] && <span>{errors[item.name]}</span>}
                  </div>
                );
              })}
              <Button
                type="submit"
                title={titleButtonAction}
                disabled={!isValid || loading}
                loading={loading}
                style={{ marginTop: 24 }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
