import React, { useState } from "react";
import {
  InputWrapper,
  Placeholder,
  StyledTextarea,
} from "./styledMultiline";
import { InputFieldProps } from ".";

export const InputFieldMultiLineIndependent: React.FC<InputFieldProps> = ({
  placeholder,
  name,
  type = "text", // Default para "text"
  value = "",
  onChange,
  style,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const isTextarea = type === "textarea"; // Verifica se o tipo é textarea

  return (
    <InputWrapper style={{ ...style }}>
      {isTextarea ? (
        <StyledTextarea
          name={name}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            onBlur && onBlur();
          }}
          isFocused={isFocused}
          hasValue={!!value}
          placeholder={placeholder} // Para suportar o placeholder no textarea
          {...props}
        />
      ) : (
        <StyledTextarea
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            onBlur && onBlur();
          }}
          isFocused={isFocused}
          hasValue={!!value}
          {...props}
        />
      )}
      <Placeholder hasValue={!!value} isFocused={isFocused}>
        {placeholder}
      </Placeholder>
    </InputWrapper>
  );
};
