import styled from "styled-components";

// Estilizando o container do seletor de arquivo
export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Estilizando o botão de upload
export const UploadButton = styled.label`
  background-color: ${(props) => props.theme.inputFocusBorder};
  color: #fff; // Cor do texto
  padding: 8px 10px; // Espaçamento interno
  border: none; // Sem borda
  border-radius: 5px; // Cantos arredondados
  cursor: pointer; // Cursor de ponteiro
  transition: background-color 0.3s; // Transição suave
  margin-bottom: 24px;
  margin-top: 24px;
  &:hover {
    background-color: #0056b3; // Cor de fundo no hover
  }
`;

// Ocultando o input real
export const HiddenInput = styled.input`
  display: none; // Ocultando o input
`;
