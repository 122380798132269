export function identificarTipoDeArquivo(
  url: string
): "imagem" | "vídeo" | "audio" | "" {
  // Converte a URL para letras minúsculas para facilitar a verificação de extensões
  const urlLower = url.toLowerCase();

  // Verifica as extensões de imagem
  const extensoesImagem = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".svg",
    ".webp",
  ];
  const extensoesVideo = [".mp4", ".avi", ".mov", ".webm", ".mkv", ".flv"];
  const extensoesAudio = [".mp3", ".wav", ".ogg", ".m4a", ".flac"];

  // Função auxiliar para verificar a extensão
  function temExtensao(extensoes: string[]): boolean {
    return extensoes.some((extensao) => urlLower.endsWith(extensao));
  }

  // Verifica se a URL tem uma extensão conhecida
  if (temExtensao(extensoesImagem)) {
    return "imagem";
  }
  if (temExtensao(extensoesVideo)) {
    return "vídeo";
  }
  if (temExtensao(extensoesAudio)) {
    return "audio";
  }

  // Caso não encontre pela extensão, tenta verificar o caminho da URL
  if (urlLower.includes("/image/")) {
    return "imagem";
  }
  if (urlLower.includes("/video/")) {
    // Poderia ser tanto vídeo quanto áudio, mas sem uma extensão conhecida, vamos assumir que é vídeo
    return "vídeo";
  }
  if (urlLower.includes("/audio/")) {
    return "audio";
  }

  // Se não puder ser identificado por caminho ou extensão
  return "";
}
