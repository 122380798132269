import api from "../conections/axios";
import { Payment } from "../types/payments";

export const paymentsApi = {
  async getPaymentsByProductId({ productId }: { productId: string }): Promise<Payment[]> {
    try {
      const response = await api.get(`/payments?productId=${productId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
